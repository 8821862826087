
body {
  height: 2000px;

  font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;;
}
.square {
  width: 150px;
  height: 150px;

  position: absolute;
}

.square1 {
  top: 0;
    background: #4a7a9f;
}

.square2 {
  top: 950px;
    background: #af8d49;
}


section {
  height: 100vh;

}
section h2 {
    text-align: center;
    padding: 30px ;
  }
.s1{
  background-color: orange;

}
.s2{
 background-color: cornflowerblue;
}
.s3{
 background-color: rosybrown;
}
.s4{
 //background-color: gray;
}

.main-container {

}

section {
  height: 100vh;

  position: relative;

}

canvas {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.text {
  font-size: 48px;
  color: white;
  position: absolute;
  top: 25%;
  left: 100px;
  opacity: 0;
}

.t {
  padding: 30px;
  text-align: center;
}
